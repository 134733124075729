/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { NavLink, useLocation } from "react-router-dom";
import { useToggle } from "@loyaltylogistix/component-library";

import { useRef } from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";

import { actionable } from "global/helpers";
import { AccessControl } from "Components/AccessControl/AccessControl";

import { t } from "i18next";
import * as styles from "./ModuleNavigation.styles";
import { Badge } from "Components/Badge";

function ModuleNavigation({ navigation = {} }) {
    const [open, toggle] = useToggle();

    const ulElm = useRef();

    const location = useLocation();
    return (
        <div css={styles.topMenu(open, navigation, ulElm)}>
            <div {...actionable(toggle)}>
                {navigation && (
                    <nav
                        aria-label={`${
                            location?.pathname?.split("/")?.[1] || "Secondary"
                        } navigation`}
                        ref={ulElm}
                    >
                        {Object.values(navigation).map(
                            ({ title, path, scoopedPermissions, count }) => (
                                <AccessControl permissionsIds={scoopedPermissions}>
                                    <NavLink
                                        key={path}
                                        to={path}
                                    >
                                        <li key={path}>
                                            {t(
                                                `pageTitles.${title
                                                    .toLowerCase()
                                                    .replace(" ", "-")}`
                                            )}{" "}
                                            {count > 0 ? <Badge count={count} /> : null}
                                        </li>
                                    </NavLink>
                                </AccessControl>
                            )
                        )}
                    </nav>
                )}
                <FontAwesomeIcon
                    icon={faChevronDown}
                    fixedWidth
                />
            </div>
        </div>
    );
}

ModuleNavigation.propTypes = {
    navigation: PropTypes.arrayOf(
        PropTypes.shape({
            scoopedPermissions: PropTypes.oneOfType([
                PropTypes.arrayOf(PropTypes.number),
                PropTypes.number,
            ]),
            route: PropTypes.string,
            title: PropTypes.string,
        })
    ),
    pageName: PropTypes.string,
};

export default ModuleNavigation;
