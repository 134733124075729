/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useHistory } from "react-router-dom";

import { useRecoilValue } from "recoil";
import { PlatformConfig } from "state/global";
import { useRoutes } from "hooks/useRoutes";

import { useTranslation } from "react-i18next";
import NavMenuLink from "./NavMenuLink";
import * as styles from "./ModuleMenu.styles";


function ModuleNavigation() {
    const isActive = (match, location, category) => {
        if (match) return true;
        if (location?.pathname.split("/")[1].includes(category)) return true;
        return false;
    };

    const history = useHistory();
    const config = useRecoilValue(PlatformConfig)

    const handleIconClick = () => {
        history.push("/");
    };

    const { t } = useTranslation();

    const [, , SIDENAVITEMS] = useRoutes();
    return (
        <nav
            aria-label="Main navigation"
            css={styles.wrapper}
        >
            <li css={styles.icon}>
                <button
                    tab-index="-1"
                    type="button"
                    aria-label="Home button"
                    onClick={handleIconClick}
                >
                    <img
                        src={config?.logo || config?.favicon || "/icons/loyalty_logo.png"}
                        alt="home"
                    />
                </button>
            </li>
            {SIDENAVITEMS.map(({ title, name, icon, path, children, count }) => (
                <NavMenuLink
                    key={name}
                    isActive={(match, location) => isActive(match, location, name)}
                    label={t(`pageTitles.${title.toLowerCase().replace(" ", "-")}`)}
                    faIcon={icon}
                    to={path}
                    count={count}
                >
                    {children}
                </NavMenuLink>
            ))}
        </nav>
    );
}

export default ModuleNavigation;
