import { AtomEffect, DefaultValue } from "recoil";

type AtomEffectOptions<T extends AtomEffect<any>> = T extends (props: infer options) => any
    ? options
    : never;

export const localStorageEffect =
    (key: string) =>
    <TRecoilState>({ setSelf, onSet }: AtomEffectOptions<AtomEffect<TRecoilState>>) => {
        const savedValue = localStorage.getItem(key);
        if (savedValue != null && savedValue !== undefined) {
            setSelf(JSON.parse(savedValue));
        }

        onSet((newValue: TRecoilState, oldValue: TRecoilState | DefaultValue, isReset: boolean) =>
            isReset
                ? localStorage.removeItem(key)
                : localStorage.setItem(key, JSON.stringify(newValue))
        );
    };
