import { checkIfPermitted } from "global/tsHelpers";
import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { PlatformUserPermissionIds } from "state/global/selectors/PlatformUserPermissions.selector";

export default function useUserHasPermissions(){

  const userPermissionIds = useRecoilValue(PlatformUserPermissionIds);
  const hasPermission = useCallback( (...requiredPermissions: number[]) => checkIfPermitted(userPermissionIds, requiredPermissions), [userPermissionIds]);

  return hasPermission;

}