/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Children, Fragment } from "react";

import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "Components/Badge";

export default function NavMenuLink({ isActive, to, label, faIcon, children, count }) {
    return (
        <li>
            <NavLink
                isActive={isActive}
                to={to}
                aria-label={label}
            >
                {Children?.count(children) > 0 ? (
                    children
                ) : (
                    <Fragment>
                        <FontAwesomeIcon
                            icon={faIcon}
                            fixedWidth
                        />
                        <p>
                            {label} {count > 0 ? <Badge count={count} /> : null}
                        </p>
                    </Fragment>
                )}
            </NavLink>
        </li>
    );
}
